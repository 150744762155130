import React, { useState } from "react";
import {
  ApplicationContainer,
  MainContainer,
  AdView,
  ArrowView,
} from "./styles";
import Header from "./UIComponents/Header";
import Footer from "./UIComponents/Footer";

function AppContainer({ props }: any) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={"main"}>
        <Header />
        <Footer />
      </div>
    </>
  );
}

export default AppContainer;
