import React from "react";
import { Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";

export default function Dataiku() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img9 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <Primaryheading primaryText="Dataiku - A Tool for Expertise" />
              <span className="sub-text">
                Dataiku - a contemporary sensation is recognized as a podium
                that systematizes not only the use of Artificial Intelligence
                (AI) but also data that bring people together to consign
                incredible business results. It is an integrated virtual
                environment where users work as they please.
              </span>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <Primaryheading primaryText="Dataiku assistance at AIdatamonks Inc" />
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 md-mb-50">
                <div className="services-item">
                  {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/4.png" alt="Services">
                              </div> */}
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Dataiku For Business Connoisseur</a>
                    </h2>
                    <p className="services-txt">
                      Dataiku promotes collaboration run AI initiatives where
                      tech and business work hand-in-hand better. It fashions a
                      single place for conversation and data assignments within
                      any team. Dataiku harmonizes with other collaborating
                      podiums such as Microsoft Teams, Slack, Atlassian and
                      Confluence.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-50">
                <div className="services-item">
                  {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/2.png" alt="Services">
                              </div> */}
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Analytical Apps with Dataiku </a>
                    </h2>
                    <p className="services-txt">
                      Dataiku impedes the quick creation of interactive visual
                      front ends for rational outcomes. It organizes analytical
                      dashboards and applications for business punters.
                    </p>
                    <ul className="icon-item mt-20">
                      <li>
                        <p className="list-text">
                          Business Dashboards- Dataiku makes it trouble-free to
                          craft out project dashboards and share them with
                          business users. Triggering updates and scheduling
                          updates for dashboards are not so difficult and make
                          sure that the most up-to-date information is
                          available. With the facility of dashboards as part of
                          Dataiku assignment project stakeholders can simply
                          track the KPIs and see AI projects and values.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          For business punters to engross themselves with AI
                          forecast- the results of AI assignments need a
                          boundary. With Dataiku Apps business analysts and data
                          scientists can simply produce apps and publish a
                          project with a few clicks. The profit is that more and
                          more people throughout an organization can make use of
                          advanced predictive insights and Artificial
                          Intelligence can provide the utmost value to the
                          business.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div>
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-12">
                <div className="sec-title3">
                  <Primaryheading
                    primaryText="                                    What makes us different?
                            "
                  />

                  <p className="margin-0 pb-40">
                    We provide the best Dataiku guidance to our clients. Dataiku
                    implementation and execution is made easy with AIdatamonks
                    Inc .{" "}
                  </p>
                  <ul className="icon-item">
                    <li>
                      <p className="list-text">
                        Our professional strategists build insightful guide to
                        train your team for various Dataiku models.
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        We deliver 24*7 support and ensure that your Dataiku is
                        always running smoothly. Our administration techniques
                        are highly-effective.{" "}
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        We employ various AI tools to deliver the performance
                        and service our clients desire. Our useful AI strategies
                        keep your machine learning road map running.{" "}
                      </p>
                    </li>
                  </ul>
                  <p className="margin-0 pb-40">
                    For more information and assistance, you can contact VSIT
                    LLC .{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-100 pb-10">
          <div className="container">
            <div className="sec-title mb-60">
              <Primaryheading primaryText=" Mechanize Analytics for a Self-Serve Business" />

              <p className="services-txt mt-30 mb-30">
                Dataiku rapidly automates business progression by faultless
                scheduling processes. It empowers the business to automate
                analytical tasks that are mostly recurring, without relying on
                IT teams or data science. This helps people to concentrate on
                more impactful and fertile data assignments.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-20 pb-10">
          <div className="container">
            <div className="sec-title mb-60">
              <Primaryheading primaryText="Web Apps and More" />
              <p className="services-txt mt-30 mb-30">
                With numerous ways to develop applications, organizations can
                construct applications with the insights predicted by them to
                drive good customer experiences and furthermore improve their
                proficiency. JavaScript, R Shiny, Bokeh, Dash and many more are
                just to name a few among those leading frameworks that Dataiku
                supports.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
