import { Route, Switch } from "react-router-dom";
import React from "react";
import Home from "../Home";
import AboutUs from "../Pages/AboutUs";
import Contact from "../Pages/Contact";
import Careers from "../Careers";
import Clients from "../Pages/Clients";
import CaseStudies from "../Pages/CaseStudies";
import Technologies from "../Pages/Technologies";
import EmpowerDevelopment from "../Pages/EmpowerDevelopment";

import BigData from "../Pages/BigData";
import Hadoop from "../Pages/Hadoop";
import MachineLearning from "../Pages/MachineLearning";
import AWS from "../Pages/Aws";
import Dataiku from "../Pages/Dataiku";
import Snowflake from "../Pages/Snowflake";
import MicrosoftAzure from "../Pages/MicrosoftAzure";
import CloudFoundation from "../Pages/CloudFoundation";
import PAMS from "../Pages/PAMS";
import SQLMorph from "../Pages/SQLMorph";
import AIStrategy from "../Pages/AIStrategy";
import DataStrategy from "../Pages/DataStrategy";
import SnowflakeAdministration from "../Pages/SnowflakeAdministration";
import DataScience from "../Pages/DataScience";
import DataEngineering from "../Pages/DataEngineering";
import CDMS from "../Pages/CDMS";
import ManagedMLOps from "../Pages/ManagedMLOps";
import Ebooks from "../Pages/Ebooks";
import Videos from "../Pages/Videos";
import Blog from "../Pages/Blog";
import FAQ from "../Pages/FAQ";

// other pages
import ComingSoon from "../Pages/ComingSoon";

export function Router() {
  return (
    <Switch>
      <Route exact path={"/"} component={Home} />
      <Route path="/home" component={Home} />
      <Route path="/AboutUs" component={AboutUs} />
      <Route path="/Contact" component={Contact} />
      <Route path="/Careers" component={Careers} />
      <Route path="/Clients" component={Clients} />
      <Route path="/CaseStudies" component={CaseStudies} />
      <Route path="/Technologies" component={Technologies} />
      <Route path="/EmpowerDevelopment" component={EmpowerDevelopment} />

      <Route path="/BigData" component={BigData} />
      <Route path="/Hadoop" component={Hadoop} />
      <Route path="/MachineLearning" component={MachineLearning} />
      <Route path="/AWS" component={AWS} />
      <Route path="/Dataiku" component={Dataiku} />
      <Route path="/Snowflake" component={Snowflake} />
      <Route path="/MicrosoftAzure" component={MicrosoftAzure} />
      <Route path="/CloudFoundation" component={CloudFoundation} />
      <Route path="/PAMS" component={PAMS} />
      <Route path="/SQLMorph" component={SQLMorph} />
      <Route path="/AIStrategy" component={AIStrategy} />
      <Route path="/DataStrategy" component={DataStrategy} />
      <Route
        path="/SnowflakeAdministration"
        component={SnowflakeAdministration}
      />
      <Route path="/DataScience" component={DataScience} />
      <Route path="/DataEngineering" component={DataEngineering} />
      <Route path="/CDMS" component={CDMS} />
      <Route path="/ManagedMLOps" component={ManagedMLOps} />
      <Route path="/Ebooks" component={Ebooks} />
      <Route path="/Videos" component={Videos} />
      <Route path="/Blog" component={Blog} />
      <Route path="/FAQ" component={FAQ} />
      <Route path="/ComingSoon" component={ComingSoon} />
    </Switch>
  );
}

export default Router;
