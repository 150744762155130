import React from "react";
import { Banner } from "./styles";
import loadable from "@loadable/component";

const Button = loadable(() => import("../DesignSystem/Button"));

export default function HeaderBanner() {
  const B1 = require("../../assets/images/1.svg");
  const B2 = require("../../assets/images/2.svg");
  const B3 = require("../../assets/images/3.svg");

  return (
    <Banner>
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h2>Connect with the Top Cloud Experts</h2>
          <p>
            AIdatamonks Inc revolutionises data management by utilising a
            cutting-edge <br />
            assortment of cloud data software applications.
          </p>

          <section className="py-3">
            <div className="d-flex justify-content-between align-items-center row">
              <div className="d-flex flex-column col-12 col-sm-4 align-items-center">
                {/* <Button variant="secondary" isHideIcon={true}>
                  Cloud Apps
                </Button> */}
                <img src={B2} />
              </div>

              <div className="d-flex flex-column col-12 col-sm-4 align-items-center">
                {/* <Button variant="secondary" isHideIcon={true}>
                  Data Analytics
                </Button> */}
                <img src={B1} />
              </div>

              <div className="d-flex flex-column col-12 col-sm-4 align-items-center">
                {/* <Button
                  title="Modern Apps"
                  variant="secondary"
                  isHideIcon={true}
                >
                  Modern Apps
                </Button> */}
                <img src={B3} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </Banner>
  );
}
