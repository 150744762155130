import React from "react";
import { Section } from "../styles";
import { Banner } from "../Home/styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import { Link } from "react-router-dom";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { TwoGridBox } from "../UIComponents/TwoGrid/styles";

export default function EmpowerDevelopment() {
  const Img11 = require("../../assets/images/11.svg");
  const services = [
    {
      title: "Manufacturing",
      para: "We assist enterprises in setting up a foundation to better gather, handle and take action on enormous data to forecast when maintenance has to be done.",
    },
    {
      title: "Trade and CPG",
      para: "We provide crucial insight to our clients to assist trade and CPG enterprises to make the most of their data, regardless of whether they are assessing the success of a campaign or calculating supply levels.",
    },
    {
      title: "Monetary Services",
      para: "Assisting clients in navigating the strictly regulated settings of fiscal data to deliver significant results, we support financial enterprises in maximizing the potential of data.",
    },
    {
      title: "Data Engineering",
      para: "At AIdatamonks, we incorporate a tried and true framework with cloud-based solutions and sector-specific knowledge to consistently assist healthcare providers in getting more value out of their insights.",
    },
  ];

  return (
    <div>
      <Banner>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="w-50">
            <h2>Cloud Infrastructure for your Projects</h2>
            <p>
              cloud infrastructure refers to the necessary elements for cloud
              computing, encompassing hardware, abstracted resources, storage,
              and network resources.
            </p>
          </div>
        </div>
      </Banner>

      <Section className="">
        <div className="container ">
          <div className="row pt-5">
            <div className="col-12 col-sm-7">
              <PrimaryHeading
                primaryText="How do you create a cloud infrastructure "
                secondaryText=""
              />
              <p>
                The infrastructure for cloud computing comprises the combination
                of hardware and software elements essential for facilitating
                cloud-based services. This encompasses computing power,
                networking capabilities, storage resources, and an interface
                that allows users to access their virtualized resources.
              </p>
              <p>
                To rank as the top web development company we depend on the set
                of industry-standard web tools and technologies mentioned as
                follows:
              </p>
              <p>
                Deeply ingrained in your surroundings Self-contained and deeply
                integrated into your enterprise environment. Flexible in scale
                and pricing to meet your specific needs. And, unless you specify
                otherwise, always private.
              </p>
            </div>
            <div className="col-12 col-sm-5">
              <img src={Img11} alt="" />
            </div>
          </div>
        </div>
      </Section>

      <div className="container ">
        {/* <Section>
          <div className="row">
            <div className={"col-6 col-sm-4"}>
              <GridBox style={{ height: 120 }}>
                <b>Think Adaptive and Elastic</b>
              </GridBox>
            </div>
            <div className={"col-6 col-sm-4"}>
              <GridBox style={{ height: 120 }}>
                <b>Think Adaptive and Elastic</b>
              </GridBox>
            </div>
            <div className={"col-6 col-sm-4"}>
              <GridBox style={{ height: 120 }}>
                <b> Think Adaptive and Elastic</b>
              </GridBox>
            </div>
            <div className={"col-6 col-sm-4"}>
              <GridBox style={{ height: 120 }}>
                <b>Think Adaptive and Elastic</b>
              </GridBox>
            </div>
            <div className={"col-6 col-sm-4"}>
              <GridBox style={{ height: 120 }}>
                <b>Think Adaptive and Elastic</b>
              </GridBox>
            </div>
            <div className={"col-6 col-sm-4"}>
              <GridBox style={{ height: 120 }}>
                <b>Think Adaptive and Elastic</b>
              </GridBox>
            </div>
          </div>
        </Section> */}

        <Section>
          <PrimaryHeading primaryText="Components that makes up Cloud Infrastructure" />
          <p className="text-center pb-4">
            Cloud infrastructure is composed of four primary groups that break
            down the main components comprising it.
          </p>
          <div className="row">
            {services.map((item: any) => {
              return (
                <div className={"col-12 col-sm-6"}>
                  <TwoGridBox>
                    <div className="d-flex align-items-center justify-content-start w-100">
                      <h5 className="text-center">{item.title}</h5>
                    </div>
                    <p>{item.para}</p>
                  </TwoGridBox>
                </div>
              );
            })}
          </div>
        </Section>
      </div>
    </div>
  );
}
