import { css } from 'styled-components';

export const size = {
  xxs: '10px', 
  xs: '12px', 
  sm: '14px', 
  base: '16px',
  lg: '18px', 
  xl: '20px', 
  xxl: '22px', 
  xxxl: '36px', 
  xxxxl: '46px', 
};

export const fontSize_XXS = css`
  font-size: ${size.xxs};
`;

export const fontSize_XS = css`
  font-size: ${size.xs};
`;

export const fontSize_SM = css`
  font-size: ${size.sm};
`;

export const fontSize_BASE = css`
  font-size: ${size.base};
`;

export const fontSize_LG = css`
  font-size: ${size.lg};
`;

export const fontSize_XL = css`
  font-size: ${size.xl};
`;

export const fontSize_XXL = css`
  font-size: ${size.xxl};
`;

export const fontSize_XXXL = css`
  font-size: ${size.xxxl};
`;

export const fontSize_XXXXL = css`
  font-size: ${size.xxxl};

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;