import React from "react";
import { Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";

export default function Ebooks() {
  return (
    <Section>
      <Primaryheading primaryText="Ebooks" />
      <div className="rs-services style2 rs-services-style2 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
        <div className="container custom">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <GridBox>
                <div className="service-wrap">
                  <div className="image-part">
                    <img src="assets/images/services/style2/1.jpg" alt="" />
                  </div>
                  <div className="content-part">
                    <h5 className="title">Hadoop</h5>
                    <div className="desc">
                      Instead Of Using One Large Computer To Store And Process
                      The Data, Hadoop Allows Clustering Multiple Computers To
                      Analyze Massive Datasets In Parallel More Quickly
                    </div>
                    {/* <p className="mt-4 d-flex justify-content-end">
                      <a
                        href="assets/pdf/ai.pdf"
                        download
                        className="text-danger"
                      >
                        Download ebook
                      </a>
                    </p> */}
                  </div>
                </div>
              </GridBox>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <GridBox>
                <div className="service-wrap">
                  <div className="content-part">
                    <h5 className="title">Machine Learning</h5>
                    <div className="desc">
                      We are a team of experienced professionals who are
                      determined to provide efficient IT solutions. Our team of
                      ML engineers has always got your back.{" "}
                    </div>
                    {/* <p className="mt-4 d-flex justify-content-end">
                      <a
                        href="assets/pdf/ai.pdf"
                        download
                        className="text-danger"
                      >
                        Download ebook
                      </a>
                    </p> */}
                  </div>
                </div>
              </GridBox>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <GridBox>
                <div className="service-wrap">
                  {/* <div className="image-part">
                                    <img src="assets/images/services/style2/3.jpg" alt="" />
                                </div> */}
                  <div className="content-part">
                    <h5 className="title">Artificial Intelligence</h5>
                    <div className="desc">
                      Artificial Intelligence (AI) is a branch of Science which
                      deals with helping machines find solutions to complex
                      problems in a more human-like fashion.
                    </div>
                    {/* <p className="mt-4 d-flex justify-content-end">
                      <a
                        href="assets/pdf/ai.pdf"
                        download
                        className="text-danger"
                      >
                        Download ebook
                      </a>
                    </p> */}
                  </div>
                </div>
              </GridBox>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
    </Section>
  );
}
