import React from "react";

export default function ComingSoon() {
  return (
    <div>
      {/* Breadcrumbs Start */}
      <div className="rs-breadcrumbs comingsoon aws-banner">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">Coming Soon...</h1>
          </div>
        </div>
      </div>
      {/* Breadcrumbs End */}
    </div>
  );
}
