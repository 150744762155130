import React from "react";
import { SectionHeading } from "../styles";

export default function Primaryheading(props: any) {
  return (
    <SectionHeading>
      <div className="d-flex align-items-center pb-4">
        <span>
          <b>{props.primaryText}</b>
        </span>
        <span>{props.secondaryText}</span>
      </div>
      <p className="tagline">{props.tagline}</p>
    </SectionHeading>
  );
}
