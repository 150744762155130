import React from "react";
import WorkWith from "../UIComponents/WorkWith";
import { Section } from "../styles";

export default function Clients() {

  return (
    <Section>
      <WorkWith />
    </Section>
  );
}
