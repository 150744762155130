import React from "react";
import { BgHeading, Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Button from "../DesignSystem/Button";
import {
  WhyWorkWithUs,
  SectionHiring,
  SectionEachPosition,
  CareersContainerStyles,
} from "./styles";
import { Link } from "react-router-dom";

export default function Careers() {
  const GSS = require("../../assets/images/careers-logo.png");

  const careersData = [
    {
      id: 1,
      title: "Data Engineer",
      description:
        "A competent Data Engineer is needed to expand our team. You will be an essential part of creating high-quality software solutions that satisfy user needs and technical constraints as a data engineer. Throughout the entire software development lifecycle, work with cross-functional teams to design, create, and test software applications. Your skill in adopting industry best practises to write clean, effective, and maintainable code will be priceless. Investigate and fix software bugs, give helpful criticism, and keep up of market developments and technological trends. Come work with us and be a part of a team that consistently enhances software development procedures to provide top-notch outcomes.",
      posted_date: "03-07-2023",
      location: "Dallas",
      work_type: "Remote",
    },
    {
      id: 2,
      title: "SAP Consultant",
      description:
        "As a member of our team, you can help make our company's SAP initiatives successful. You will collaborate directly with clients as a SAP Consultant to fully grasp their business needs and offer creative solutions utilising your knowledge of SAP modules. Work with cross-functional teams, examine business procedures, and set up SAP systems to satisfy client requirements. Deliver high-quality results by putting your powerful problem-solving abilities and in-depth understanding of SAP functions to use. Utilise this chance to advance your career and have a major impact on the SAP consulting industry.",
      posted_date: "03-07-2023",
      location: "Dallas",
      work_type: "Remote",
    },
    {
      id: 2,
      title: "RPA Developer",
      description:
        "As an RPA Developer on our team, you will be at the cutting edge of automation technology. You will be a vital part of planning, creating, and putting our clients' robotic process automation solutions into action as an RPA Developer. Work with cross-functional teams to comprehend business processes, spot opportunities for automation, and create effective RPA workflows. Make use of your knowledge of RPA tools and technology to build bots that accelerate productivity-enhancing tasks. Provide technical assistance, troubleshoot and debug automation problems, and stay current on RPA improvements. Join us as we use automation to transform organisations, and help to advance RPA innovation..",
      posted_date: "03-07-2023",
      location: "Dallas",
      work_type: "Remote",
    },
  ];
  return (
    <div>
      <div className="container">
        <CareersContainerStyles>
          <div className="item--sub-title">Careers </div>
          <h3>
            <b>
              From startups to Fortune 500 businesses,AIdatamonks offers the
              best internships and employment.
            </b>
          </h3>
          {careersData.map((item: any) => {
            return (
              <div className="grid">
                <div className="d-flex">
                  <img src={GSS} alt="" />
                  <div className="d-flex flex-column">
                    <h5>{item.title}</h5>
                    <p>
                      <i className="fa fa-paperclip"></i>Posted date :{" "}
                      {item.posted_date}
                    </p>

                    <p>
                      <i className="fa fa-map"></i>
                      {item.location}
                    </p>
                    <p>
                      <i className="fa fa-life-ring"></i>
                      {item.work_type}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="description">{item.description}</p>
                </div>
                <div>
                  <Link to="/ReachUs">
                    <button className="">Apply now</button>
                  </Link>
                </div>
              </div>
            );
          })}
        </CareersContainerStyles>
      </div>
      {/* <BgHeading>
        <Section className="bg-gray py-0">
          <div className="container">
            <Section className="about-main-content">
              <Primaryheading primaryText="Careers" secondaryText="" />
              <div className="d-flex justify-content-center flex-column align-items-center">
                <p className="text-center">
                  Starlight launches branded products with a proprietary system
                  flow that <br />
                  gives you the highest return on investment in the online and
                  advertising
                  <br />
                  super world.
                </p>
                <Link to="/Contact">
                  <Button className="mt-3">Join With Us</Button>
                </Link>
              </div>
            </Section>
            <WhyWorkWithUs className="d-flex justify-content-center flex-column align-items-center mt-0 pb-3">
              <h3 className="pb-5">Why Work with us</h3>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <GridBox>
                    <h4>Mission Statement</h4>
                    <p>
                      Our mission is to empower our clients by providing
                      top-notch IT consulting services that help them navigate
                      the rapidly evolving digital landscape. We strive to build
                      long-lasting relationships with our clients by
                      understanding their unique needs and delivering customized
                      solutions that meet those needs. Our team of dedicated
                      professionals is committed to staying at the forefront of
                      technological advancements and leveraging them to help our
                      clients achieve success
                    </p>
                  </GridBox>
                </div>
                <div className="col-12 col-sm-6">
                  <GridBox>
                    <h4>Vision Statement</h4>
                    <p>
                      Our vision is to become the leading provider of IT
                      consulting services in the industry by delivering
                      innovative solutions that empower businesses to achieve
                      their goals and stay ahead of the competition.
                    </p>
                  </GridBox>
                </div>
              </div>
            </WhyWorkWithUs>
          </div>
        </Section>

        <Section className="">
          <div className="container">
            <SectionHiring>
              <p className="badge">Open positions</p>
              <Primaryheading primaryText="We're Hiring" secondaryText="" />
              <p className="text-center">
                Welcome to our IT consulting company's current openings page! We
                are a fast-growing company that is always on the lookout for
                talented and motivated individuals to join our team. Presented
                below are the existing job opportunities that are currently
                available for applicants.
              </p>
              <div className="section-positions-list mt-5">
                {positionsData.map((item: any) => {
                  return (
                    <SectionEachPosition className="section-each-position">
                      <h2>{item.designation}</h2>
                      <div className="row d-flex justify-content-between align-items-center">
                        <p className="col-12 col-sm-9 m-0">
                          {item.description}
                        </p>
                        <div className="col-12 col-sm-3 d-flex justify-content-end">
                          <Link to="/Contact">
                            <Button>APPLY NOW</Button>
                          </Link>
                        </div>
                      </div>
                    </SectionEachPosition>
                  );
                })}
              </div>
            </SectionHiring>
          </div>
        </Section>
      </BgHeading> */}
    </div>
  );
}
