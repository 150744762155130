import React from "react";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";

export default function BigData() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText="Hadoop & Big Data " />
                  <p className="margin-0 pt-15">
                    Big Data has a significant influence on organisations all
                    over the world, with applications in areas as diverse as
                    healthcare, insurance, transportation, logistics, and
                    customer service.
                  </p>
                  <p className="margin-0 pt-15">
                    As a Big Data Engineer, you'll be on your way to a
                    fascinating, ever-changing profession that's expected to
                    develop rapidly through 2025 and beyond.
                  </p>
                  <p className="margin-0 mt-3">
                    This certification course will introduce you to the world of
                    big data engineering. Expert seminars and Ask Me Anything
                    sessions are included.
                  </p>
                  <p className="margin-0 mt-3">
                    Using interactive live sessions, practical laboratories, and
                    industry projects, master job-critical skills such as Big
                    Data and Hadoop frameworks, how to harness the capability of
                    AWS services, and how to utilise the database management
                    tool and MongoDB to store data.
                  </p>
                  <p className="margin-0 mt-3">
                    Through hands-on exposure with the tools and systems used by
                    big data scientists and engineers, you will get a grasp of
                    what insights big data can give.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText="Big data key featrues" />
                  <p className="margin-0 pt-15">
                    You will learn how to execute predictive modelling and use
                    graph analytics to model problems by following along with
                    the given code. This specialty will teach you how to ask the
                    proper questions about data, communicate successfully with
                    data scientists, and do basic dataset exploration.
                  </p>

                  <p className="margin-0 pt-15">
                    Fundamental approaches such as data mining and stream
                    processing will be covered. You'll also learn how to use
                    MapReduce, Hadoop cluster, to develop and execute PageRank
                    algorithms. You'll discover how big data has enhanced web
                    search and how online advertising works.
                  </p>
                  <p className="margin-0 pt-15">
                    You will have a better knowledge of the numerous uses of big
                    data approaches in industry and research by the conclusion
                    of this course.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Learning outcomes" />
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          MapReduce knowledge and <br /> application
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        he MapReduce task is mainly divided into 2 phases i.e.
                        Map phase and Reduce phase. Map: As the name suggests
                        its main use is to map the input data in key-value
                        pairs.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Understanding the frequency of event <br />{" "}
                          occurrences in large data
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        The frequency of a particular data value is the number
                        of times the data value occurs.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Develop algorithms for stream processing and
                          <br /> counting frequent elements
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        On the processing side, there are a few choices: Samza,
                        Storm and Spark Streaming are the most popular stream
                        processing frameworks.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
