import React from "react";
import HeaderBanner from "./HeaderBanner";
import FourGrid from "../UIComponents/FourGrid";
import { Section } from "../styles";
import WorkWith from "../UIComponents/WorkWith";
import { CountableWrapper, NumberColumn, Form } from "./styles";
import TwoGrid from "../UIComponents/TwoGrid";
import Button from "../DesignSystem/Button";
import { Subscribe } from "../styles";

export default function Home() {
  const Bg1 = require("../../assets/images/5.svg");
  const FormBG = require("../../assets/images/7.svg");

  return (
    <>
      <HeaderBanner />

      <Section>
        <FourGrid />
      </Section>

      {/* <Section>
        <WorkWith />
      </Section> */}

      <Section
        className="pb-5 bg-cover"
        style={{
          backgroundImage: `url(${Bg1})`,
          backgroundPosition: "center",
          width: "100%",
          backgroundRepeat: "repeat-x",
          backgroundSize: "contain",
          height: "auto",
        }}
      >
        <div className="container">
          <CountableWrapper>
            <h5>
              We Are the Big League Data Service Provider That Brings <br />{" "}
              Evolutionary Computation and Analytics Projects to Reality.
            </h5>
          </CountableWrapper>
          <div className="row pb-4">
            <div className="col-12 col-sm-4">
              <NumberColumn>
                <h5>1000</h5>
                <p>
                  We created ingest funnels for <br /> individual clients using
                  our open-
                  <br /> source importation platform.
                </p>
              </NumberColumn>
            </div>
            <div className="col-12 col-sm-4">
              <NumberColumn>
                <h5>500+</h5>
                <p>
                  Projects were carried out by our <br /> dedicated team of
                  specialists in the <br /> data engineering department for over
                  a decade
                </p>
              </NumberColumn>
            </div>
            <div className="col-12 col-sm-4">
              <NumberColumn>
                <h5>90%</h5>
                <p>
                  Aggregate rate on retention for <br /> DataOps, management
                  services
                  <br /> and MLOps
                </p>
              </NumberColumn>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <TwoGrid />
      </Section>

      <Section
        className="pb-5 bg-cover"
        style={{
          backgroundImage: `url(${FormBG})`,
          backgroundPosition: "center",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "auto",
        }}
      >
        <div className="container">
          <CountableWrapper>
            <h5>
              Join us in a collaborative effort to embark on the first stage of
              our <br /> journey towards achieving success together.
            </h5>
          </CountableWrapper>
          <div className="row pb-4 pt-5 align-items-center">
            <div className="col-12 col-sm-7">
              <>
                <p className="text-white" style={{ lineHeight: 2 }}>
                  We are the ideal solution for enterprises and startups because
                  of our knowledge and expertise in providing system updates,
                  hosting services, security, and maintenance of data platforms.
                  Take advantage of our specialists and advanced technology that
                  investigates options that most supplies won't
                </p>
                <p className="text-white" style={{ lineHeight: 2 }}>
                  We are the ideal solution for enterprises and startups because
                  of our knowledge and expertise in providing system updates,
                  hosting services, security, and maintenance of data platforms.
                  Take advantage of our specialists and advanced technology that
                  investigates options that most supplies won't
                </p>
              </>
            </div>
            <div className="col-12 col-sm-5">
              <Form>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      placeholder="Company Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <input
                      type="text"
                      placeholder="Contact Number"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <textarea
                      placeholder="Write a short message here."
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-12 d-flex justify-content-center">
                    <Button>Let's connect</Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="d-flex justify-content-center align-items-center ">
          <Subscribe className="flex-column px-3 px-sm-0">
            <p>Subscribe to stay tuned for latest updates.</p>
            <input type="text" className="form-control" />
            <Button>Subscribe</Button>
          </Subscribe>
        </div>
      </Section>
    </>
  );
}
