import React from "react";
import { Section } from "../styles";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";

export default function CaseStudies() {
  return (
    <div>
      <>
        <div className="container">
          <Section>
            <Primaryheading primaryText="Case Studies" />
            <div className="row">
              <div className={"col-12 col-sm-12"}>
                <GridBox>
                  <h5>Data Engineering Case Study</h5>
                  <p>
                    We helped a large e-commerce company improve their data
                    infrastructure and data processing capabilities. Our team of
                    data engineering experts designed and implemented a robust
                    data pipeline that processed millions of transactions per
                    day with ease. We also optimized their data storage and
                    retrieval processes, enabling them to extract valuable
                    insights from their data and make informed decisions. As a
                    result, the company saw a significant improvement in their
                    operational efficiency and an increase in revenue
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-12"}>
                <GridBox>
                  <h5>E-commerce Case Study</h5>
                  <p>
                    We worked with a startup e-commerce company to develop a
                    custom e-commerce platform that catered to their specific
                    needs. Our team of software developers designed and built a
                    scalable platform that could handle high volumes of traffic
                    and transactions. We also integrated various third-party
                    services, including payment gateways and shipping providers,
                    to provide a seamless customer experience. The platform
                    helped the company establish a strong online presence and
                    increase their sales.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-12"}>
                <GridBox>
                  <h5>Technology Migration Case Study</h5>
                  <p>
                    We assisted a large financial services company in migrating
                    their legacy systems to a modern cloud-based platform. Our
                    team of experts designed and executed a seamless migration
                    strategy that minimised downtime and ensure data integrity.
                    We also provided training and support to the company's staff
                    to ensure a smooth transition. As a result, the company was
                    able to streamline their operations and reduce costs.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-12"}>
                <GridBox>
                  <h5>Management Consulting Case Study</h5>
                  <p>
                    We provided management consulting services to a mid-sized
                    manufacturing company that was struggling with low
                    profitability. Our team of consultants analysed the
                    company's processes and identified areas for improvement. We
                    developed a comprehensive strategy that focused on
                    optimising their supply chain, reducing waste, and improving
                    their production processes. As a result, the company was
                    able to increase their profitability and gain a competitive
                    edge in the market
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-12"}>
                <GridBox>
                  <h5>Data Validation Case Study</h5>
                  <p>
                    We worked with a healthcare company to validate their data
                    and ensure compliance with regulatory requirements. Our team
                    of data validation experts designed and executed a
                    validation strategy that included data profiling, data
                    quality checks, and data completeness checks. We also
                    provided recommendations on how to improve their data
                    management processes. As a result, the company was able to
                    improve their data quality and ensure compliance with
                    regulatory requirements.
                  </p>
                </GridBox>
              </div>
            </div>
          </Section>
        </div>
      </>
    </div>
  );
}
