import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "./ComingSoon";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function Snowflake() {
  return (
    <div>
      {/* Breadcrumbs Start */}
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img15 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="About Snowflake & Administration" />
              <p className="sub-text">
                Snowflake is a cloud-backed data warehouse platform that reduces
                the work of Database Administrators. It boosts your data
                adoption techniques by providing apt resources and task
                automation. Maintaining data platforms, especially on the cloud
                becomes complex. The Snowflake administration does this task for
                you.
              </p>
            </div>
          </div>
        </div>
      </Section>
      {/* Breadcrumbs End */}

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Snowflake assistance at AIdatamonks Inc Inc." />
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 mb-50">
                <div className="services-item">
                  {/* <div className="services-icon">
                          <img src="assets/images/services/style3/2.png" alt="Services">
                      </div> */}
                  <div className="services-content">
                    <p className="services-txt">
                      Our team at AIdatamonks Inc Inc. has worked with multiple
                      clients. With this experience, we know that companies have
                      distinct requirements. They face challenges on different
                      levels. Our experienced Snowflake administrators offer the
                      following services:{" "}
                    </p>
                    <ul className="icon-item mt-20">
                      <li>
                        <p className="list-text">
                          User outline and access administration
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Data platform cost management
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Automated platform development and recommendation
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Use of appropriate and suitable tools to support the
                          Snowflake platform
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Properly organised data architecture and structure
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Continuously checking and managing Snowflake tools
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Troubleshooting platform related errors and problems
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Working connectivity between data sources and
                          Snowflake platforms
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Help you save cost as you pay only for the services
                          you use
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
