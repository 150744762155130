import styled from "styled-components";
import { fontSize_XL, fontSize_XXXL } from "../customFonts";

export const WhyWorkWithUs = styled.div`
  h3 {
    ${fontSize_XXXL};
    font-weight: bold;
  }

  h4 {
    ${fontSize_XL};
    font-weight: 600;
    margin-bottom: 14px;
  }

  h4:after {
    content: "";
    border-bottom: 8px solid ${(props: any) => props.theme.danger.DANGER};
    width: 32px;
    border-radius: 4.5px;
    display: block;
    margin: 0 auto;
    margin-top: -14px;
    position: absolute;
    margin-left: -46px;
  }
`;

export const SectionHiring = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .section-positions-list {
    .section-each-position {
      border-bottom: 1px solid #cdcdcd;
      padding-bottom: 40px;
      padding-top: 40px;

      h2 {
        padding-bottom: 20px;
        font-weight: bold;
        ${fontSize_XL};
      }
    }
  }
`;

export const SectionEachPosition = styled.div`
  h2 {
    color: ${(props: any) => props.theme.primary.THEME};
  }
`;

export const CareersContainerStyles = styled.div`
  padding: 20px 0;
  position: relative;
  margin-top: 30px;

  h3 {
    margin: 0;
    font-size: 36px;
    line-height: 1.33333;
    color: #0d2252;
    font-weight: 900;
    padding: 20px 0;
    font-family: "Roboto", Sans-serif !important;

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  .item--sub-title {
    color: #0e0e0e;
    font-weight: 400;
    margin-bottom: 9px;
    padding-left: 75px;
    position: relative;
  }

  .item--sub-title:before {
    content: "";
    width: 50px;
    height: 3px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-color: #ff0040;
    background-image: -webkit-linear-gradient(left, #ff0040, #ff0040);
    box-shadow: 0 7px 16px rgba(12, 12, 12, 0.22);
  }

  .grid {
    padding: 40px 34px;
    background-color: #fff;
    -webkit-border-radius: 7px;
    -khtml-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    -khtml-box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    -moz-box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    -ms-box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    -o-box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    -webkit-transition: all 300ms linear 0ms;
    -khtml-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    -o-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
    margin-bottom: 60px;

    &:hover {
      box-shadow: 0 20px 20px rgba(12, 12, 12, 0.14);
    }

    h5 {
      font-size: 20px;
      margin-bottom: 8px;
      font-weight: bold;
      font-family: "Roboto", Sans-serif !important;
      margin-bottom: 16px;
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      border: 1px solid #ccc;
      margin-right: 14px;
      padding: 5px;
    }

    i {
      padding-right: 10px;
      color: #105ea4;
    }

    button {
      box-shadow: 0 7px 16px rgba(12, 12, 12, 0.22);
      background-color: #105ea4 !important;
      font-size: 16px;
      line-height: 55px;
      padding: 0 42px;
      z-index: 1;
      overflow: hidden;
      color: white;
      border-radius: 12px;
      border: none;

      &:hover {
        background-color: red !important;
      }
    }

    .description {
      line-height: 32px;
      padding: 30px 0;
    }
  }
`;
