import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "./ComingSoon";
import { Section } from "../styles";
import { GridBox } from "../UIComponents/FourGrid/styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function MicrosoftAzure() {
  return (
    <div>
      <Section className="bg-gray">
        {/* Breadcrumbs Start */}
        <div className="rs-breadcrumbs img1 machine-learning-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading
                primaryText="Microsoft Azure. The innovate everywhere and create anything
                    cloud AZURE"
              />
              <span className="sub-text">
                Microsoft Azure is a public cloud platform with more than 200
                products and many services accessible over the internet.
              </span>
              <p className="sub-text">
                Since 2008, Microsoft Azure has grown to become the 2nd largest
                of the top three public cloud platforms. Just behind market
                leader Amazon Web Services (AWS) and ahead of Google Cloud
                Platform (GCP).
              </p>
            </div>
          </div>
        </div>
      </Section>
      {/* Breadcrumbs End */}

      {/* Services Section Start */}
      <Section>
        <div className="">
          <div className="container custom">
            <div className="services-box-area bg20">
              <div className="row margin-0">
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item">
                      <div className="services-content">
                        <h5 className="title">
                          <a href="">Trust cloud</a>
                        </h5>
                        <p className="margin-0">
                          Get full security from the ground up, backed by a team
                          of experts, and proactive compliance trusted by
                          governments and startups.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item">
                      <div className="services-content">
                        <h5 className="title">
                          <a href="">Use hybrid</a>
                        </h5>
                        <p className="margin-0">
                          On-premises, across multiple clouds, and at the edge.
                          we’ll meet you where you are. Integrate and manage
                          your environments with services designed for hybrid
                          cloud.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item">
                      <div className="services-content">
                        <h5 className="title">
                          <a href="">Build on terms</a>
                        </h5>
                        <p className="margin-0">
                          With a commitment to open source, and support for all
                          languages and frameworks, build how you want, and
                          deploy where you want to.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item no-border">
                      <div className="services-content">
                        <h5 className="title">
                          <a href="">The Future ready</a>
                        </h5>
                        <p className="margin-0">
                          Continuous innovation from Microsoft supports your
                          development today, and your product visions for
                          tomorrow for sure.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Services Section End */}

      {/* Services Section Start */}
      <Section>
        <div
          id="rs-services"
          className="rs-services main-home services-style1 home-4-style bg7 pt-95 pb-100 md-pt-70 md-pb-65"
        >
          <div className="container">
            <div className="sec-title3 text-center mb-65 md-mb-45">
              <PrimaryHeading
                primaryText=" What is Microsoft Azure Cloud? &amp; Benefits of
                    Microsoft Azure Cloud"
              />
              <div className="heading-border-line" />
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Azure Compute</a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        This service group empowers companies to quickly
                        provision Linux and Windows virtual machines, achieve
                        high availability through autoscaling and supercharge
                        app development using a serverless architecture.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Azure Networking</a>
                      </h5>
                      <p className="services-txt">
                        This feature set allows organizations to create hybrid
                        computing frameworks that utilize both private and
                        public cloud infrastructure. By doing so, users can
                        seamlessly deliver their custom applications to
                        thousands of virtual machines.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Azure Storage</a>
                      </h5>
                      <p className="services-txt">
                        Microsoft’s virtual datacenters provide companies with
                        agile connectivity and scalable infrastructure,
                        eliminating the need for on-premises hardware. All data
                        written to Azure is encrypted and accessible over HTTP
                        or HTTPS.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 md-mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Azure Analytics</a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Azure supercharges business decision-making through its
                        advanced analytics services, which offer unrivaled time
                        to insight. Users can take advantage of a fully managed
                        cloud Hadoop or Spark environment with real-time data
                        stream processing.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 sm-mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Azure Backup</a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        This group of services simplifies data recovery,
                        allowing users to back up virtual machines, SQL
                        workloads and on-premises VMware with a single click.
                        Through Azure, companies can quickly restore data using
                        VSS snapshot or fsfreeze without fear of application
                        consistency.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Azure Disaster Recovery</a>
                      </h5>
                      <p className="services-txt">
                        Microsoft helps keep critical applications running
                        during planned and unplanned outages through its
                        built-in disaster recovery tools. Users can replicate,
                        failover and recover processes as needed, while also
                        minimizing downtime by sequencing multi-tier
                        applications running on multiple virtual machines.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Services Section End */}
    </div>
  );
}
