import React, { FunctionComponent, ReactNode } from 'react';
import { Button as CustomButton } from './styles';
import {
  getActiveStyle,
  getHoverStyle,
  getStyle,
  size as sizeType,
  defaultStyle,
} from './helpers';
import { variant as variantType } from './buttonVariants';
import _ from 'lodash';

export interface ButtonProps {
  children: ReactNode;
  loading?: boolean;
  size?: sizeType;
  variant?: variantType;
  disabled?: boolean;
  onClick?: (...args: unknown[]) => unknown;
  title?: string;
  className?: string;
  onKeyPress?: any;
  id?: string;
  type?: any;
  value?: any;
  style?: any;
  tooltip?: any;
  tabIndex?: number;
  isHideIcon?: boolean;
}

const Button: FunctionComponent<ButtonProps> = (props: ButtonProps) => { 
  const defaultHoverStyle = {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: 'solid 5px red',
  };

  const defaulActiveStyle = {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: 'solid 5px red',
  };

  const style = props.variant
    ? _.merge(
        {},
        getStyle(props.variant, props.size, props.disabled),
        props.style
      )
    : _.merge({}, defaultStyle, props.style);

  const hoverStyle = props.variant
    ? _.merge({}, getHoverStyle(props.variant), props.style)
    : _.merge({}, defaultHoverStyle, props.style);

  const activeStyle = props.variant
    ? _.merge({}, getActiveStyle(props.variant), props.style)
    : _.merge({}, defaulActiveStyle, props.style);
    
  return (
    <CustomButton
      as='button'
      className={props.className}
      tabIndex={props.tabIndex}
      disabled={props.disabled || false}
      generalStyle={style}
      hoverStyle={hoverStyle}
      activeStyle={activeStyle}
      title={props.title}
      type={props.type}
      onClick={props.onClick}
    >
      <b className={`text-truncate`}>
        {props.children}        
        {!props.isHideIcon && <i className="fa fa-chevron-right ms-2"/>}
      </b>
    </CustomButton>
  );
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  size: 'lg',
  title: '',
  variant: 'primary',
  className: '',
  type: 'button',
};

export default Button;