import React from "react";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";

export default function MachineLearning() {
  const T9 = require("../../assets/images/t9.png");

  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img5 machine-learning-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Machine Learning frameworks" />
              <p className="sub-text">
                We are a team of experienced professionals who are determined to
                provide efficient IT solutions. Our team of ML engineers has
                always got your back. Our engineers are qualified, well-trained,
                and experienced Machine Learners with a strong sense of heavy
                data usage
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-5 md-mb-50">
                <div className="images-part">
                  <img
                    src={T9}
                    alt="Images"
                    className="w-100"
                    style={{ opacity: 0.8 }}
                  />
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-6 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText=" Machine Learning" />
                  <p className="margin-0 pt-15">
                    For a company to rise higher in the market, customer
                    satisfaction is extremely important, which can be achieved
                    by identifying their preferences and behaviour. Today,
                    Machine Learning and Artificial Intelligence technologies
                    play a crucial role in making this possible.
                  </p>
                  <p className="margin-0 pt-15">
                    These technologies offer enterprises data about the trends
                    in customer behaviour and helps them interpret the
                    preferences and expectations of their existing and potential
                    customers.
                  </p>
                  <p className="margin-0 mt-3">
                    Modern-day applications and tools play a key role in
                    reading, solving, and applying the latest algorithms to
                    carry out various operations. These objectives are
                    unachievable without the assistance of professional Machine
                    Learning engineers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText="How can AIdatamonks Inc  assist you?" />

                  <p className="margin-0 pt-15">
                    We are a team of experienced professionals who are
                    determined to provide efficient IT solutions. Our team of ML
                    engineers has always got your back. Our engineers are
                    qualified, well-trained, and experienced Machine Learners
                    with a strong sense of heavy data usage.{" "}
                  </p>
                  <ul className="margin-0 mt-4">
                    <h5>This is what makes them different:</h5>
                    <li className="margin-0 mt-3">
                      They collect appropriate data and employ it to build
                      models using the ML tools for enhanced results. They focus
                      on the present value while also considering the future
                      possibilities of Machine Learning.
                    </li>
                    <li className="margin-0 mt-3">
                      Images, audio, and video usually contain heavy data. Our
                      ML engineers make the best use of algorithms and data
                      pipelines for creating error-free and responsive
                      programmes running complex data
                    </li>
                    <li className="margin-0 mt-3">
                      They create highly-responsive MLOps platforms to carry out
                      a wide range of Machine Learning data operations, which
                      also includes designing serverless deployment models.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Here’s what well-trained Machine Learning engineers do" />
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Decision making <br /> technology
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Generate data that is utilised in escalating a
                        business’s growth using data-driven decision making
                        technology
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Accurate <br /> results
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Ensure the correct and fruitful use of data models for
                        100% accurate results
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Work with the <br /> ML frameworks
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Precisely and closely work with the ML frameworks,
                        tools, and models
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Working on large <br /> data
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Constantly working on large data files and data research
                        to derive the latest information useful for the company
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 sm-mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Avoid all <br /> problems
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Detect and raise issues on time so that potential risks
                        and problems are completely avoided
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
