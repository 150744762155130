import React from 'react';
import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import ComingSoon from './ComingSoon';
import Snowflake from './Snowflake';

export default function SnowflakeAdministration() {
    return (
       <Snowflake/>
    )
}