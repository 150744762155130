import React from "react";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function DataScience() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Data Science?" />

              <p className="sub-text">
                Data science is one of the most in-demand occupations of the
                decade, with a need for data scientists who can analyse data and
                convey findings to guide data-driven choices at an all-time
                high.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Adoption of DATA SCIENCE in Finance!" />

              <p className="mt-20">
                At large Indian firms, the overall adoption of Analytics and
                Data Science is around.
                <b className="text-danger" style={{ fontSize: "40px" }}>
                  74.5%
                </b>{" "}
                In other words, by 2021, 74.5% of large firms in India will have
                analytics penetration greater than 1.0%. This is higher than the
                adoption rate of 70% in 2019.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 md-mb-50">
                <div className="services-item">
                  <div className="services-content">
                    <p className="services-title">
                      Data Science professional certificates can assist you in
                      becoming work-ready, whether you're trying to start a new
                      profession or change your existing one. You may learn at
                      your own speed, anytime and whenever suits you best. Enrol
                      today to begin your journey along a new professional path.
                      At any moment, you can suspend your studies or cancel your
                      membership.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-50">
                <div className="services-item">
                  <div className="services-content">
                    <p>
                      The emphasis on applied learning is considerable in this
                      Professional Certificate. All subsequent courses, except
                      for the first, involve a series of hands-on labs on the
                      Cloud that will provide you with practical skills that can
                      apply to real-world situations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading
                primaryText="   How will Data Science change the world?
                        "
              />

              <div className="mt-30 mb-30">
                <img
                  className="hovers-logos rs-grid-img w-50"
                  src="assets/images/pages/data-science/4.png"
                  alt=""
                />
              </div>
              <div className="mt-30 mb-30">
                <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/_fjUaDj77NI"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
