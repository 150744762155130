import React, { FunctionComponent, useState } from "react";
import { SectionHeading } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function WorkWith(props: any) {
  const C1 = require("../../assets/images/4.svg");

  const services = [
    {
      src: C1,
    },   
  ];

  return (
    <div className={"container"}>
      <PrimaryHeading
        primaryText="Companies"
        secondaryText="We Work with"
        tagline="Our customers are critical to how we think, perform, and grow as we work towards empowering businesses through simplified solutions & services"
      />
      <section className="d-flex justify-content-center">
        <div className="row">
          {services.map((item: any) => {
            return (
                <img src={item.src}/>
            );
          })}
        </div>
      </section>
    </div>
  );
}
