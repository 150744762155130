import React from "react";
import AppContainer from "./components/AppContainer";
import { BrowserRouter } from "react-router-dom";
import Router from "./components/routes";
import { ScrollToTop } from "react-router-scroll-to-top";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppContainer>
        <Router />
      </AppContainer>
    </BrowserRouter>
  );
}

export default App;
