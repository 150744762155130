import React from 'react';

export default function Hadoop() {
    return (
        <div>
        {/* Breadcrumbs Start */}
        <div className="rs-breadcrumbs img2 aws-banner">
            <div className="container">
            <div className="breadcrumbs-inner">
                <h1 className="page-title">
                What is purpose of  <img src='assets/images/pages/hadoop/4.png' style={{ width: '6%' }}/> Hadoop?
                {/* <span className="watermark">tools and model</span> */}
                </h1>
                <span className="sub-text">Hadoop is an open source framework that is used to efficiently store and process large datasets ranging in size from gigabytes to petabytes of data. </span>
                <p className='sub-text'>Instead of using one large computer to store and process the data, Hadoop allows clustering multiple computers to analyze massive datasets in parallel more quickly.
</p>
            </div>
            </div>
        </div>
        {/* Breadcrumbs End */}
        {/* About Section Start */}
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
            <div className="container custom">
            <div className="row">
                <div className="col-lg-6 md-mb-50">
                <div className="images-part">
                    <img src="assets/images/pages/hadoop/2.jpg" alt="Images" />
                </div>
                </div>
                <div className="col-lg-6 pl-60 md-pl-15">
                <div className="sec-title">
                    <h2 className="title pb-22">
                        Data Storage System
                    </h2>
                    <p className="margin-0 pt-15">The Hadoop Distributed File System (HDFS) is the primary data storage system used by Hadoop applications. HDFS employs a NameNode and DataNode architecture to implement a distributed file system that provides high-performance access to data across highly scalable Hadoop clusters.
                    </p>
                    <p className="margin-0 pt-15">
                    With our unmatched expertise and familiarity with AWS, we can render assistance to derive costeffective benefits. Our goal is to serve you better with AWS while also guiding you at every step.
                    </p>
                </div>
                </div>
            </div>
            </div>
        </div>
        {/* About Section End */}
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
            <div className="container custom">
            <div className="row">
                <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                    <h2 className="title pb-22">
                    Hadoop Use cases?
                    </h2>
                    {/* <p className="margin-0 pt-15">We are a team of experienced professionals who are determined to provide efficient IT solutions. Our
                                    team of ML engineers has always got your back. Our engineers are qualified, well-trained, and
                                    experienced Machine Learners with a strong sense of heavy data usage. </p> */}
                    <ul className="margin-0 mt-4" style={{ listStyle: "square" }}> 
                                    <h5></h5>
                                    <li className="margin-0 mt-4">
                                    Financial services companies use analytics to assess risk, build investment models, and create trading algorithms; Hadoop has been used to help build and run those applications.

                                    </li>
                                    <li className="margin-0 mt-4">
                                    Retailers use it to help analyze structured and unstructured data to better understand and serve their customers.

                                    </li>
                                    <li className="margin-0 mt-4">
                                    In the asset-intensive energy industry Hadoop-powered analytics are used for predictive maintenance, with input from Internet of Things (IoT) devices.
                                    </li>
                                    <li className="margin-0 mt-4">Telecommunications companies can adapt all the aforementioned use cases. For example, they can use Hadoop-powered analytics to execute predictive maintenance on their infrastructure. Big data analytics can also plan efficient network paths and recommend optimal locations for new cell towers or other network expansion. To support customer-facing operations telcos can analyze customer behavior and billing statements to inform new service offerings.</li>
                                    <li className="margin-0 mt-4">There are numerous public sector programs, ranging from anticipating and preventing disease outbreaks to crunching numbers to catch tax cheats.
</li>
                                </ul>
                </div>
                </div>
            </div>
            </div>
        </div>
        {/* Services Section End */}
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
            <div className="container">
            <div className="sec-title mb-60">
                <h2 className="title">
                HDFS Goals

                </h2>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 md-mb-50">
                <div className="services-item">
                    {/* <div className="services-icon">
                                    <img src="assets/images/services/style3/4.png" alt="Services">
                                </div> */}
                    <div className="services-content">
                    <h2 className="services-title"><a href="">Millions of <br />Files</a></h2>
                    <p className="services-txt"> Store millions of large files, each greater than tens of gigabytes, and filesystem sizes reaching tens of petabytes </p>
                    </div>
                </div>
                </div>
                <div className="col-lg-6 col-md-6 mb-50">
                <div className="services-item">
                    {/* <div className="services-icon">
                                    <img src="assets/images/services/style3/1.png" alt="Services">
                                </div> */}
                    <div className="services-content">
                    <h2 className="services-title"><a href="">Access to many <br /> Files</a></h2>
                    <p className="services-txt">Optimize for large, streaming reads and writes rather than low-latency access to many small files. </p>
                    </div>
                </div>
                </div>
                <div className="col-lg-6 col-md-6 mb-50">
                <div className="services-item">
                    {/* <div className="services-icon">
                                    <img src="assets/images/services/style3/2.png" alt="Services">
                                </div> */}
                    <div className="services-content">
                    <h2 className="services-title"><a href="">Inexpensive  <br /> commodity</a></h2>
                    <p className="services-txt"> Use a scale-out model based on inexpensive commodity servers with internal JBOD rather than RAID to achieve large-scale storage.  </p>
                    </div>
                </div>
                </div>
                <div className="col-lg-6 col-md-6 mb-50">
                <div className="services-item">
                    {/* <div className="services-icon">
                                    <img src="assets/images/services/style3/3.png" alt="Services">
                                </div> */}
                    <div className="services-content">
                    <h2 className="services-title"><a href="">Application  <br />level</a></h2>
                    <p className="services-txt">Accomplish availability and high throughput through application-level replication of data. Support the functionality and scale requirements of MapReduce processing
</p>
                    </div>
                </div>
                </div>
               
            </div>
            </div>
        </div>
        {/* Services Section End */}
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
            <div className="container">
            <div className="sec-title mb-60">
                <h2 className="title">
                Important Topic YARN Architecture
                </h2>
                <p className='mt-5'>
                YARN (Yet Another Resource Negotiator) has been introduced to Hadoop with version 2.0 and solves a few issues with the resources scheduling of MapReduce in version 1.0. In order to understand the benefits of YARN, we have to review how resource scheduling worked in version 1.0. A MapReduce job is split by the framework into tasks (Map tasks, Reducer tasks) and each task is run on of the DataNode machines on the cluster. For the execution of tasks, each DataNode machine provided a predefined number of slots (map slots, reducers slots). The JobTracker was responsible for the reservation of execution slots for the different tasks of a job and monitored their execution. 

                </p>
                <p>If the execution failed, it reserved another slot and re-started the task. It also cleans up temporary resources and makes the reserved slot available to other tasks.</p>

                <p>Hadoop is used in these and other big data programs because it is effective, scalable, and is well supported by large vendor and user communities. Hadoop is a de facto standard in big data.
</p>
            </div>
            <img src="assets/images/pages/hadoop/3.jpg" className="md-pt-70 md-pb-70 w-100" />
            </div>
        </div>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
            <div className="container">
            <div className="sec-title mb-60">
                <h2 className="title">
                Learning tutorials links:
                </h2>
            </div>
            <p className='mb-4'><a href="https://www.tutorialspoint.com/hadoop/index.htm">https://www.tutorialspoint.com/hadoop/index.htm</a></p>
            <p className='mb-4'><a href="https://www.javatpoint.com/hadoop-tutorial">https://www.javatpoint.com/hadoop-tutorial</a></p>
            <p className='mb-4'><a href="https://machinelearninggeek.com/introduction-to-hadoop/">https://machinelearninggeek.com/introduction-to-hadoop/</a></p>
            <p className='mb-4'><a href="https://enos.itcollege.ee/~jpoial/allalaadimised/reading/Apache-Hadoop-Tutorial.pdf">https://enos.itcollege.ee/~jpoial/allalaadimised/reading/Apache-Hadoop-Tutorial.pdf</a></p>
            </div>
        </div>
        {/* Partner Section Start */}
        <div className="rs-patter-section black-bg pt-80 pb-75">
            <div className="container custom">
            <div className="rs-carousel owl-carousel" data-loop="true" data-items={5} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-md-device={5} data-md-device-nav="false" data-md-device-dots="false" data-center-mode="false" data-ipad-device2={4} data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-ipad-device={4} data-ipad-device-nav="false" data-ipad-device-dots="false" data-mobile-device={2} data-mobile-device-nav="false" data-mobile-device-dots="false">
                <div className="logo-img">
                <a href="">
                    <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/1.png" alt="" />
                    <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/1.png" alt="" />
                </a>
                </div>
                <div className="logo-img">
                <a href="">
                    <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/2.png" alt="" />
                    <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/2.png" alt="" />
                </a>
                </div>
                <div className="logo-img">
                <a href="">
                    <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/3.png" alt="" />
                    <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/3.png" alt="" />
                </a>
                </div>
                <div className="logo-img">
                <a href="">
                    <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/4.png" alt="" />
                    <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/4.png" alt="" />
                </a>
                </div>
                <div className="logo-img">
                <a href="">
                    <img className="hovers-logos rs-grid-img" src="assets/images/partner/main-home/5.png" alt="" />
                    <img className="mains-logos rs-grid-img " src="assets/images/partner/main-home/5.png" alt="" />
                </a>
                </div>
            </div>
            </div>
        </div>
        {/* Partner Section End */}
        </div>
    )
}